export const useBannerStore = defineStore('bannerList', () => {
  const { user } = storeToRefs(useUserStore());
  const apppage = shallowRef('main');
  const options = computed(() => ({
    apppage: apppage.value,
    count: 100,
    age_rating: user.value?.current_profile?.age_rating || '18',
    // "device": App.device.getDeviceKind()
  }));

  const {
    data,
    fetch: fetchBanner,
    clean,
  } = useApiData(
    `/banner/list/`,
    {
      query: options,
      transform: d => d.banners || [],
      watch: [apppage, options],
    },
  );

  const filtered = computed(() => {
    if (!data.value) {
      return [];
    }
    return data.value.filter(item => item.click_action.algorithm === 'throw_to_video');
});

  const banners = computed(async () => {
    const vodPromises = filtered.value.map(banner => {
      return fetchVodFullInfo(banner.click_action.params[0]);
    });
    return await Promise.all(vodPromises);
  });

  // version 2 (real banner):
  const fetchBannersWithInfo = async () => {
    const vodPromises = filtered.value.map(async banner => {
      const vodInfo = await fetchVodFullInfo(banner.click_action.params[0]);
      return { ...banner, vodInfo };
    });
    return await Promise.all(vodPromises);
  };
  // Ожидаем данные и возвращаем готовый массив данных
  const banners2 = computed(async () => {
    return await fetchBannersWithInfo();
  });

  return {
    apppage,
    banners,
    banners2,
    //
    fetchBanner,
    clean,
  };
});
